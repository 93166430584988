import { graphql, Link } from "gatsby"
import React from "react"
import {
  makeStyles,
  List,
  ListItem,
  ListItemText,
  ListItemIcon,
  Grid,
  Container,
} from "@material-ui/core"
import { Assignment, Slideshow, Group, Create } from "@material-ui/icons"

import SEO from "../components/seo"
import BookletLayout from "../layouts/BookletLayout"
import NotificationBox from "./NotificationBox"
import useIsMobile from "../hooks/useIsMobile"

const useStyles = makeStyles(theme => ({
  link: {
    textDecoration: `none`,
    color: "inherit",
  },
  content: {
    display: "flex",
    flexDirection: "column",
  },
  paper: {
    border: "2px solid #cccccc",
  },
}))

const Template = ({ data }) => {
  const classes = useStyles()
  const {
    html,
    frontmatter: {
      path,
      type,
      title,
      video,
      slides,
      hideNotes,
      hideBonusHomework,
    },
  } = data.markdownRemark
  const isMobile = useIsMobile()

  if (type === "week") {
    return (
      <BookletLayout>
        <SEO title={title} />
        <Container maxWidth="md">
          <h1>{title}</h1>
          <Grid container>
            <Grid item xs={12} md={12}>
              {video && (
                <iframe
                  className={classes.video}
                  width="100%"
                  height={isMobile ? "300" : "500"}
                  src={video}
                  frameBorder="0"
                  // allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                  allowFullScreen
                ></iframe>
              )}
            </Grid>
          </Grid>
          <Grid
            container
            spacing={3}
            direction={isMobile ? "column-reverse" : "row"}
          >
            <Grid item xs={12} md={8}>
              <div dangerouslySetInnerHTML={{ __html: html }} />
            </Grid>
            <Grid item xs={12} md={4}>
              <NotificationBox title="🚀 Resources">
                <List>
                  {!hideNotes && (
                    <Link className={classes.link} to={`${path}/notes`}>
                      <ListItem button>
                        <ListItemIcon>
                          <Create />
                        </ListItemIcon>
                        <ListItemText primary="Notes" />
                      </ListItem>
                    </Link>
                  )}
                  <a className={classes.link} target="_blank" href={slides}>
                    <ListItem button>
                      <ListItemIcon>
                        <Slideshow />
                      </ListItemIcon>
                      <ListItemText primary="Slides" />
                    </ListItem>
                  </a>
                  <Link className={classes.link} to={`${path}/homework`}>
                    <ListItem button>
                      <ListItemIcon>
                        <Assignment />
                      </ListItemIcon>
                      <ListItemText primary="Homework" />
                    </ListItem>
                  </Link>
                  {!hideNotes && (
                    <Link
                      className={classes.link}
                      to={`${path}/bonus-homework`}
                    >
                      <ListItem button>
                        <ListItemIcon>
                          <Assignment />
                        </ListItemIcon>
                        <ListItemText primary="Bonus Homework" />
                      </ListItem>
                    </Link>
                  )}
                  <Link className={classes.link} to={`${path}/group-chat`}>
                    <ListItem button>
                      <ListItemIcon>
                        <Group />
                      </ListItemIcon>
                      <ListItemText primary="Group chat" />
                    </ListItem>
                  </Link>
                </List>
              </NotificationBox>
            </Grid>
          </Grid>
        </Container>
      </BookletLayout>
    )
  }
  return (
    <BookletLayout>
      <SEO title={title} />
      <Container maxWidth="md">
        <h1>{title}</h1>
        <div dangerouslySetInnerHTML={{ __html: html }} />
      </Container>
    </BookletLayout>
  )
}

export const query = graphql`
  query ArticleByPath($pagePath: String!) {
    markdownRemark(frontmatter: { path: { eq: $pagePath } }) {
      html
      frontmatter {
        path
        title
        author
        video
        type
        slides
        hideNotes
        hideBonusHomework
      }
    }
  }
`

export default Template
